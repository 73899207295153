
import { defineComponent } from "vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import { RequestParams, RequestResponse } from "@/plugins/iamport";
import { useStore } from "vuex";
import pg from "@/data/pg";

export default defineComponent({
  name: "Authorization",
  components: { DefaultButton },
  data() {
    const store = useStore();
    return { store, redirectTo: "/" };
  },
  methods: {
    me() {
      this.$axios.get("/member/me").then((res) => {
        this.$injectToken(res.data.access);
        this.store
          .dispatch("member/updateToken", {
            access: res.data.access,
            refresh: res.data.refresh,
          })
          .then(() => {
            this.$flutter.callHandler("updateToken", {
              access: res.data.access,
              refresh: res.data.refresh,
            });
          });
        if (this.redirectTo === "/") {
          this.$flutter.callHandler("selectGnb", {
            path: "/",
          });
        } else {
          this.$router.replace(this.redirectTo);
        }
      });
    },
    signOut() {
      this.deleteDevice();
      this.store.dispatch("member/signOut").then(() => {
        this.$injectToken(null);
        this.$flutter.callHandler("updateToken", {
          access: "",
          refresh: "",
        });
        this.$router.replace("/login/before");
      });
    },
    doCertificate() {
      if (window.IMP) {
        window.IMP.init(pg.iamportId);
      }

      this.$axios
        .post("/member/certificate/issue")
        .then((res) => {
          this.callPGCertificate(res.data.merchant_uid);
        })
        .catch((e) => {
          alert(e);
          this.$flutter.callHandler("showToast", {
            message: "인증에 실패하였습니다. (1)",
            color: "error",
          });
        });
    },
    callPGCertificate(merchant_uid: string) {
      if (process.env.is_develop) {
        return;
      }

      const data: RequestParams = {
        merchant_uid: merchant_uid,
      };
      const pgCallbackCertificate = (response: RequestResponse) => {
        let data = {
          raw: response,
        };

        this.$axios
          .post("/member/certificate/process", data)
          .then(() => {
            if (response.success) {
              this.store.commit("main/setIsLoading", true);
              this.$flutter.callHandler("showToast", {
                message: "인증에 성공하였습니다.",
                color: "success",
              });
              this.me();
            } else {
              this.$flutter.callHandler("showToast", {
                message: "인증에 실패하였습니다. (2)",
                color: "error",
              });
            }
          })
          .catch(() => {
            this.$flutter.callHandler("showToast"),
              {
                message: "잠시후 다시 시도해주세요.",
              };
          });
      };

      window.IMP.certification(data, pgCallbackCertificate);
    },
    deleteDevice() {
      return this.$axios.delete("/device/mobile");
    },
  },
  created() {
    this.$flutter.callHandler("hideGnb");

    const script1 = document.createElement("script");
    const script2 = document.createElement("script");
    script1.setAttribute("src", "https://code.jquery.com/jquery-1.12.4.min.js");
    script2.setAttribute(
      "src",
      "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js"
    );
    document.head.appendChild(script1);
    document.head.appendChild(script2);
  },
  beforeUnmount() {
    this.$flutter.callHandler("showGnb");
  },
  mounted() {
    const path = this.$route.query as { redirect: string } | null;
    if (path && path?.redirect) {
      this.redirectTo = path.redirect;
    }
  },
});
